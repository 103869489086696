import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function SectionPresentation() {
  return (
    <div className="relative bg-white">
      <div className="h-56 sm:h-72 lg:absolute lg:left-0 lg:h-full lg:w-1/2 rounded-tr-100">
        <div
          className="h-full w-full object-cover rounded-tr-[150px]" 
        >
          <StaticImage 
              className="h-full w-full object-cover rounded-tr-[150px]" 
              src="../../images/praticiens-spring-medicare.jpg"
              alt="Réseau Spring Medicare"
          />
        </div>
      </div>
      <div className="relative mx-auto max-w-7xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16">
        <div className="mx-auto max-w-2xl lg:mr-0 lg:ml-auto lg:w-1/2 lg:max-w-none lg:pl-10">
          <div>
            <div className="flex h-12 w-12 items-center justify-center rounded-md bg-[#4DB2E0] text-white">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
            </div>
          </div>
          <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">
          Réseau Spring Medicare
          </h2>
            <p className="mt-5 text-lg text-gray-500">
                La médecine au cours des 30 dernières années a fait des pas de géant dans toutes les directions : recherche, décodage du génome, imagerie médicale, télémédecine, robotique chirurgicale, nanochirurgie, biologie cellulaire etc.
            </p>
            <p className="mt-5 text-lg text-gray-500">
                Dans cet univers médical hyperspécialisé – qui découpe le corps humain en tranches de plus en plus circonscrites – il existe parfois un décalage entre la performance médicale en constante progression et le ressenti du patient, du fait d’une prise en charge humaine et psychologique souvent moins performante.
            </p>
            <p className="mt-5 text-lg text-gray-500">
                Spring-MediCare propose ainsi aux praticiens une plateforme de dialogue et d’échange, notamment : une tribune à tous les praticiens désireux de s’exprimer, une information scientifique, des conférences, des formations et des échanges de pratiques pour mieux faire connaissance.
            </p>
            <p className="mt-5 text-lg text-gray-500">
                Dans un second temps, Spring MediCare se tournera vers les patients pour leur proposer une information, différents services et en particulier une plateforme de réservation en ligne de rendez-vous avec des praticiens, choisis en fonction de leur souci de l’éthique et de l’avènement d’un processus thérapeutique encore plus performant, humain et bienveillant.
            </p>
          <div className="mt-8 overflow-hidden">
            <dl className="-mx-8 -mt-8 flex flex-wrap">
              <div className="flex flex-col px-8 pt-8">
                <dt className="order-2 text-base font-medium text-gray-500">Praticiens</dt>
                <dd className="order-1 text-2xl font-bold text-[#4DB2E0] sm:text-3xl sm:tracking-tight">500</dd>
              </div>
              <div className="flex flex-col px-8 pt-8">
                <dt className="order-2 text-base font-medium text-gray-500">Prise de RDV</dt>
                <dd className="order-1 text-2xl font-bold text-[#4DB2E0] sm:text-3xl sm:tracking-tight">24/7</dd>
              </div>
              <div className="flex flex-col px-8 pt-8">
                <dt className="order-2 text-base font-medium text-gray-500">Digitalisé</dt>
                <dd className="order-1 text-2xl font-bold text-[#4DB2E0] sm:text-3xl sm:tracking-tight">100%</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}
