import React from "react"
import { StaticImage } from "gatsby-plugin-image"

  
  export default function SectionPraticiens() {
    return (
      <div className="bg-white">
        <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-12">
            <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
              <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">Découvrez notre réseau de médecins, thérapeutes et praticiens psychocorporels</h2>
              {/* <p className="text-xl text-gray-500">
                Ornare sagittis, suspendisse in hendrerit quis. Sed dui aliquet lectus sit pretium egestas vel mattis
                neque.
              </p> */}
            </div>
            <ul
              className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:max-w-5xl lg:grid-cols-3"
            > 

                <li>
                  <a href="/praticien/eric-allaire-239/">
                    <div className="space-y-6">
                      <StaticImage 
                          className="mx-auto h-40 w-40 rounded-full xl:h-56 xl:w-56"
                          src="../../markdown-pages/praticien/eric-allaire-239/Allaire.jpg"
                          alt=""
                      />
                      <div className="space-y-2">
                        <div className="space-y-1 text-lg font-medium leading-6">
                          <h3>Prof. Eric Allaire</h3>
                          <p className="text-spring-bleu">Professeur de chirurgie vasculaire et endovasculaire Chirurgie de la dysfonction érectile</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>

                <li>
                  <a href="/praticien/jean-pierre-duboc-252/">
                    <div className="space-y-6">
                      <StaticImage 
                          className="mx-auto h-40 w-40 rounded-full xl:h-56 xl:w-56"
                          src="../../markdown-pages/praticien/jean-pierre-duboc-252/Duboc.png"
                          alt=""
                      />
                      <div className="space-y-2">
                        <div className="space-y-1 text-lg font-medium leading-6">
                          <h3>Dr. Jean-Pierre Duboc</h3>
                          <p className="text-[#4DB2E0]">Médecin homéopathe, spécialisé en médecine intégrative</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>

                <li>
                  <a href="/praticien/philippe-lamy-236/">
                    <div className="space-y-6">
                      <StaticImage 
                          className="mx-auto h-40 w-40 rounded-full xl:h-56 xl:w-56"
                          src="../../markdown-pages/praticien/philippe-lamy-236/Philippe Lamy Thérapeute de couple.jpg"
                          alt=""
                      />
                      <div className="space-y-2">
                        <div className="space-y-1 text-lg font-medium leading-6">
                          <h3>Philippe Lamy</h3>
                          <p className="text-[#4DB2E0]">Thérapeute systémicien (champ social, travail, couple…) Fondateur de Spring-MediCare</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </li> 


               <li>
                  <a href="/praticien/sarah-essahraoui-10/">
                    <div className="space-y-6">
                      <StaticImage 
                          className="mx-auto h-40 w-40 rounded-full xl:h-56 xl:w-56"
                          src="../../markdown-pages/praticien/sarah-essahraoui-10/Sarah Essahraoui - PP Spring MediCare.png"
                          alt=""
                      />
                      <div className="space-y-2">
                        <div className="space-y-1 text-lg font-medium leading-6">
                          <h3>Sarah Essahraoui</h3>
                          <p className="text-[#4DB2E0]">Naturopathe et praticienne en massage thaï traditionnel</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>

                <li>
                  <a href="/praticien/milan-mitrovic-57/">
                    <div className="space-y-6">
                      <StaticImage 
                          className="mx-auto h-40 w-40 rounded-full xl:h-56 xl:w-56"
                          src="../../markdown-pages/praticien/milan-mitrovic-57/Milan Mitrovic - PP Spring MediCare.png"
                          alt=""
                      />
                      <div className="space-y-2">
                        <div className="space-y-1 text-lg font-medium leading-6">
                          <h3>Milan Mitrovic</h3>
                          <p className="text-[#4DB2E0]">Therapie somato émotionnelle</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>

                <li>
                  <a href="/praticien/christel-bourgogne-243/">
                    <div className="space-y-6">
                      <StaticImage 
                          className="mx-auto h-40 w-40 rounded-full xl:h-56 xl:w-56"
                          src="../../markdown-pages/praticien/christel-bourgogne-243/Bourgogne.png"
                          alt=""
                      />
                      <div className="space-y-2">
                        <div className="space-y-1 text-lg font-medium leading-6">
                          <h3>Christel Bourgogne</h3>
                          <p className="text-[#4DB2E0]">Psychologue Directrice de Regards Psy Lyon</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </li> 


                <li>
                  <a href="/praticien/sophie-cheurfa-60/">
                    <div className="space-y-6">
                      <StaticImage 
                          className="mx-auto h-40 w-40 rounded-full xl:h-56 xl:w-56"
                          src="../../markdown-pages/praticien/sophie-cheurfa-60/Sophie Cheufra- PP Spring MediCare.png"
                          alt=""
                      />
                      <div className="space-y-2">
                        <div className="space-y-1 text-lg font-medium leading-6">
                          <h3>Sophie Cheurfa</h3>
                          <p className="text-[#4DB2E0]">Sophrologue Relaxologue</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>

                <li>
                  <a href="/praticien/romain-ponzio-69/">
                    <div className="space-y-6">
                      <StaticImage 
                          className="mx-auto h-40 w-40 rounded-full xl:h-56 xl:w-56"
                          src="../../markdown-pages/praticien/romain-ponzio-69/Romain Ponzio - PP Spring MediCare.png"
                          alt=""
                      />
                      <div className="space-y-2">
                        <div className="space-y-1 text-lg font-medium leading-6">
                          <h3>Romain Ponzio</h3>
                          <p className="text-[#4DB2E0]">Psychologue clinicien</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>

                <li>
                  <a href="/praticien/michele-bree-52/">
                    <div className="space-y-6">
                      <StaticImage 
                          className="mx-auto h-40 w-40 rounded-full xl:h-56 xl:w-56"
                          src="../../markdown-pages/praticien/michele-bree-52/Michèle Brée - PP Spring MediCare.png"
                          alt=""
                      />
                      <div className="space-y-2">
                        <div className="space-y-1 text-lg font-medium leading-6">
                          <h3>Michèle Brée</h3>
                          <p className="text-[#4DB2E0]">Psychologue (Université de Genève), Psychopraticienne en analyse transactionnelle</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>

                <li>
                  <a href="/praticien/françoise-bruel-41/">
                    <div className="space-y-6">
                      <StaticImage 
                          className="mx-auto h-40 w-40 rounded-full xl:h-56 xl:w-56"
                          src="../../markdown-pages/praticien/françoise-bruel-41/Françoise BRUEL - PP Spring MediCare.png"
                          alt=""
                      />
                      <div className="space-y-2">
                        <div className="space-y-1 text-lg font-medium leading-6">
                          <h3>Françoise BRUEL</h3>
                          <p className="text-[#4DB2E0]">Art-thérapeute</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>

                <li>
                  <a href="/praticien/amelie-guigou-15/">
                    <div className="space-y-6">
                      <StaticImage 
                          className="mx-auto h-40 w-40 rounded-full xl:h-56 xl:w-56"
                          src="../../markdown-pages/praticien/amelie-guigou-15/Amelie Guigou - PP Spring MediCare.png"
                          alt=""
                      />
                      <div className="space-y-2">
                        <div className="space-y-1 text-lg font-medium leading-6">
                          <h3>Amelie GUIGOU</h3>
                          <p className="text-[#4DB2E0]">Psychologue</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>


                <li>
                  <a href="/praticien/isabelle-rodrigues-53/">
                    <div className="space-y-6">
                      <StaticImage 
                          className="mx-auto h-40 w-40 rounded-full xl:h-56 xl:w-56"
                          src="../../markdown-pages/praticien/isabelle-rodrigues-53/Isabelle Rodrigues - PP Spring MediCare.png"
                          alt=""
                      />
                      <div className="space-y-2">
                        <div className="space-y-1 text-lg font-medium leading-6">
                          <h3>Isabelle Rodrigues</h3>
                          <p className="text-[#4DB2E0]">Thérapeute psychocorporel</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>


                
            </ul>
          </div>
        </div>
      </div>
    )
  }
  