import React, { Component } from 'react'
import { Link } from 'gatsby'
import { CheckCircleIcon, ChevronRightIcon, EnvelopeIcon } from '@heroicons/react/solid'
// import { getImage, GatsbyImage } from 'gatsby-plugin-image'
// import { StaticImage } from "gatsby-plugin-image"

// Search component
class Search extends Component {
  state = {
    query: '',
    results: [],
  }


  getSearchResults(query) {
    var index = window.__FLEXSEARCH__.fr.index
    var store = window.__FLEXSEARCH__.fr.store
    if (!query || !index) {
      return []
    } else {
      var results = []

      Object.keys(index).forEach(idx => {
        results.push(...index[idx].values.search(query))
      })

      results = Array.from(new Set(results))

      var nodes = store
        .filter(node => (results.includes(node.id) ? node : null))
        .map(node => node.node)

      return nodes
    }
  }

  search = event => {
    const query = event.target.value
    if (this.state.query.length > 2) {
      const results = this.getSearchResults(query)
      this.setState({ results: results, query: query })
    } else {
      this.setState({ results: [], query: query })
    }
  }

  render() {
    const ResultList = () => {
      if (this.state.results.length > 0) {
        this.state.results = this.state.results.slice(0,10);
        return this.state.results.map((page, i) => (
          <div className="item-search border-b border-gray-400" key={i}>
                <Link to={page.url} className="link block hover:bg-white hover:rounded-md">
                    {/* <h4>{page.title}</h4>
                    <p>{page.url_photo}</p> */}

                    <div className="flex items-center px-4 py-4 sm:px-6">
                        <div className="flex min-w-0 flex-1 items-center">
                            <div className="flex-shrink-0">
                                <img className="h-12 w-12 rounded-full" src={page.url + page.url_photo} />
                                {/* <StaticImage 
                                    className="h-12 w-12 rounded-full"
                                    // src="/images/Philippe Lamy Thérapeute de couple.jpg"
                                    src={"/" + page.url_photo}
                                    // src={"/markdown-pages" + page.url + page.url_photo}
                                    alt=""
                                /> */}
                                {/* <GatsbyImage className="mx-auto h-12 w-12 rounded-full" image={getImage("/images/" + page.url_photo)} /> */}
                                {/* <img className="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" /> */}
                            </div>
                            <div className="min-w-0 flex-1 px-4 md:gap-4">
                                <div>
                                <p className="truncate text-sm font-bold text-gray-800">{page.praticien_prenom} {page.praticien_nom} - {page.praticien_cabinet}</p>
                                <p className="mt-2 flex items-center text-sm text-gray-500">
                                  {page.titre} - {page.description}
                                </p>
                                {/* <p className="mt-2 flex items-center text-sm text-gray-500">
                                    <EnvelopeIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <span className="truncate">{application.applicant.email}</span>
                                </p> */}
                                </div>
                                {/* <div className="hidden md:block">
                                    <div>
                                        <p className="text-sm text-gray-900">
                                        Applied on <time dateTime={application.date}>{application.dateFull}</time>
                                        </p>
                                        <p className="mt-2 flex items-center text-sm text-gray-500">
                                        <CheckCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400" aria-hidden="true" />
                                        {application.stage}
                                        </p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div>
                            <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                    </div>
                </Link>
          </div>
        ))
      } else if (this.state.query.length > 2) {
        return (
            <div className="p-4">
                Pas de résultat pour {this.state.query}
            </div>
        )
      } else if (
        this.state.results.length === 0 &&
        this.state.query.length > 0
      ) {
        return (
            <div className="p-4">
                Veuillez saisir au moins 3 caractères.
            </div>
        )
      } else {
        return ''
      }
    }

    return (
        <div className={this.props.classNames}>
            <div className="mt-1 flex rounded-md shadow-sm mt-10">
                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-xl">
                    Votre praticien
                </span>
                <input
                    className="search__input py-3 px-4 block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-[#236b8c] focus:ring-[#236b8c] sm:text-xl"
                    type="text"
                    onChange={this.search}
                    placeholder={'Recherche'}
                />
                {/* <button
                    type="submit"
                    onClick={this.search}
                    className="inline-flex items-center rounded-md border border-transparent bg-[#236b8c] px-4 py-2 text-xl font-medium text-white shadow-sm hover:[#236b8c] focus:outline-none focus:ring-2 focus:ring-[#236b8c] focus:ring-offset-2"
                >
                    Rechercher
                </button> */}
            </div>
            <div className="search__list bg-gray-50 text-left my-4 rounded-md">
                <ResultList />
            </div>
        </div>
    )
  }

}

export default Search
