import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Search from "../Search";

export default function SectionRecherche() {
  return (
    <div className="relative bg-gray-50 px-4 pt-4 pb-20 sm:px-6 lg:px-8 lg:pt-24 lg:pb-28">
      <div className="absolute inset-0">
        <div className="h-1/3 bg-[#65bce4] sm:h-2/3" />
      </div>
      <div className="relative mx-auto max-w-7xl lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Prenez rendez-vous avec un praticien
          </h2>

          {/* <div className="mt-1 flex rounded-md shadow-sm mt-10">
                <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-xl">
                  Votre praticien
                </span>
                <input
                  type="text"
                  name="username"
                  id="username"
                  autoComplete="username"
                  className=" py-3 px-4 mr-5 block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-[#236b8c] focus:ring-[#236b8c] sm:text-xl"
                />
                <button
                    type="submit"
                    className="inline-flex items-center rounded-md border border-transparent bg-[#236b8c] px-4 py-2 text-xl font-medium text-white shadow-sm hover:[#236b8c] focus:outline-none focus:ring-2 focus:ring-[#236b8c] focus:ring-offset-2"
                >
                    Rechercher
                </button>

            </div> */}

          <Search />
        </div>
        <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
          <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
            <div className="flex-shrink-0">
              <StaticImage
                className="h-48 w-full object-cover"
                src="../../images/addictions-dependances.jpg"
                alt=""
              />
            </div>
            <div className="flex flex-1 flex-col justify-between bg-white p-6">
              <div className="flex-1">
                <a
                  href="/specialite/therapie-trouble-comportement-alimentaire/addictions-dependances/"
                  className="mt-2 block"
                >
                  <p className="text-xl font-semibold text-gray-900">
                    Anorexie / Boulimie, addictions et dépendances
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    Anorexie / Boulimie, addictions et dépendances Amour et
                    nourriture, un enjeu émotionnel complexe…Mais qu’est-ce que
                    l’amour ? Le sentiment d’amour procède à la fois d’un élan
                    vers les autres, vers quelques personnes ou vers une
                    personne en particulier qui devient alors l’objet de toute
                    notre ferveur amoureuse, de toute notre attention, de toutes
                    nos attentes
                  </p>
                </a>
              </div>
              <div className="mt-6 flex items-center"></div>
            </div>
          </div>
          <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
            <div className="flex-shrink-0">
              <StaticImage
                className="h-48 w-full object-cover"
                src="../../images/haptonomie-soin.jpg"
                alt=""
              />
            </div>
            <div className="flex flex-1 flex-col justify-between bg-white p-6">
              <div className="flex-1">
                <a href="/specialite/haptonomie-soin/" className="mt-2 block">
                  <p className="text-xl font-semibold text-gray-900">
                    L'haptonomie, un soin bienveillant peu connu
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    L'haptonomie est un soin énergétique holistique et intuitif
                    – par le toucher ou massage – qui prend en compte l’état
                    émotionnel du patient, ses tensions et/ou les besoins
                    énergétiques exprimés par tout son corps.
                  </p>
                </a>
              </div>
              <div className="mt-6 flex items-center"></div>
            </div>
          </div>
          <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
            <div className="flex-shrink-0">
              <StaticImage
                className="h-48 w-full object-cover"
                src="../../images/haut-potentiel-philo-cognition.jpg"
                alt=""
              />
            </div>
            <div className="flex flex-1 flex-col justify-between bg-white p-6">
              <div className="flex-1">
                <a
                  href="/specialite/therapie-douance-hpi/haut-potentiel-philo-cognition/"
                  className="mt-2 block"
                >
                  <p className="text-xl font-semibold text-gray-900">
                    Haut potentiel et philo-cognition, de la connaissance
                    clinique à l’IRM fonctionnel
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    Haut potentiel et philo-cognition, de la connaissance
                    clinique à l’IRM fonctionnel Les enfants à haut potentiel
                    intellectuel (HP, philocognitifs, surdoués ou précoces) sont
                    identifiés et étudiés depuis plusieurs décennies Depuis
                    plusieurs décennies on étudie les enfants dits « Précoces »,
                    HP (ou « à haut potentiel intellectuel), « Surdoués » ou
                    seulement « Doués ».
                  </p>
                </a>
              </div>
              <div className="mt-6 flex items-center"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
