import React from "react"
import { Helmet } from "react-helmet"

import Header from "src/components/headers/header"

// import SectionIntro from "src/components/index/sectionIntro"
// import SectionMultiCat from "src/components/index/sectionMultiCat"
// import SectionCTA from "src/components/index/sectionCTA"
// import SectionMaison from "src/components/index/sectionMaison"
// import SectionMaisonPage from "src/components/index/sectionMaisonPage"
// import SectionDroit from "src/components/index/sectionDroit"
// import SectionSante from "src/components/index/sectionSante"
// import SectionSantePage from "src/components/index/sectionSantePage"
// import SectionArgent from "src/components/index/sectionArgent"
// import SectionVehicule from "src/components/index/sectionVehicule"
// import SectionVehiculePage from "src/components/index/sectionVehiculePage"


import SectionRecherche from "src/components/index/sectionRecherche"
import SectionPraticiens from "src/components/index/sectionPraticiens"
import SectionPresentation from "src/components/index/sectionPresentation"

import Footer from "src/components/footers/footer"

/*

<SectionIntro />
<SectionMultiCat />
<SectionCTA />
<SectionMaison />
<SectionMaisonPage />
<SectionDroit />
<SectionSante />
<SectionSantePage />
<SectionArgent />
<SectionVehicule />
<SectionVehiculePage />

*/

export default function index() {

  return (
    <div className="bg-white">
      <Helmet>
        <title>Spring Medicare</title>
        <meta name="description" content="Koudepouce simplifie votre vie au quotidien." />
      </Helmet>
      <header className="sticky top-0 z-50 bg-white">
        <Header />
      </header>

      <main className="">
        <div className="hidden bg-[#65bce4] lg:block">
          <div className="mx-auto max-w-7xl">
            <p className="text-base leading-7 text-white lg:px-8">
              Réseau d'échange entre médecins et thérapeutes psychocorporels
            </p>
          </div>
        </div>
        <SectionRecherche />
        <SectionPraticiens />
        <SectionPresentation />

      </main>

      <footer>
        <Footer />
      </footer>
    </div>
  )
}